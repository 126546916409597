import React, {Component} from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

//  import MembershipModal from 'web/components/membership_modal';
import AnnouncementBanner from 'web/components/announcement_banner';
import MarketLayout from 'web/components/market_layout';
import LoadingOverlay from 'web/components/loading_overlay';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {SignUpSuccessModalCta} from 'web/components/sign_up_success_modal';
import ReferralBanner from 'web/components/referral_banner';

import HomePageModule from '../home_page_module';
import FeaturedContentCarousel from '../featured_content_carousel';
import InterestsModal from '../interests_modal';
import {actions} from '../../duck';

const TIMEOUT_TO_OPEN_ZIP_MODAL = 5000; // 5 seconds
const TIMEOUT_TO_OPEN_NO_FEES_MODAL = 2000; // 2 seconds

export const membershipStorageKey = 'isMembershipConfirmed';

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.renderStaticModule = this.renderStaticModule.bind(this);
    // Disable membership modal https://goodeggs.atlassian.net/browse/ECOMM-36
    //  this.state = {isMembershipModalOpen: false};
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    // Disable membership modal https://goodeggs.atlassian.net/browse/ECOMM-36
    // if (
    //   queryParams.get('membership') === 'active' ||
    //   (!membershipModalAlreadyOpened && this.props.user?.membership?.isMember)
    // ) {
    //   this.setState({isMembershipModalOpen: true});
    // }
    let isRecentlyLoggedIn = false;
    if (queryParams.get('recently-logged-in') === 'true') {
      isRecentlyLoggedIn = true;
    }

    this.props.dispatch(
      actions.fetchMerchandisingFeatures({
        showAllFeatures: this.props.showAllFeatures,
        featureFlags: this.props.features,
        interests: this.props.selectedFoodInterests,
        isLoggedIn: Boolean(this.props.user),
      }),
    );

    this.startTimeout({isRecentlyLoggedIn});
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  startTimeout({isRecentlyLoggedIn}) {
    if (this.props?.user != null && this.props.settings.welcomeFeesPromoEnabled) {
      // If user is a member, placed orders before or was not redirected by the login/signup page,
      // do not show modal
      if (
        this.props.user.membership?.isMember ||
        this.props.user.orderCount > 0 ||
        !isRecentlyLoggedIn
      ) {
        return;
      }

      this.timeout = setTimeout(() => {
        this.openNoFeesModal();
      }, TIMEOUT_TO_OPEN_NO_FEES_MODAL);
      return;
    }

    if (this.props.zipCode) return;
    this.timeout = setTimeout(() => {
      this.openZipModal();
    }, TIMEOUT_TO_OPEN_ZIP_MODAL);
  }

  clearTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  goHome() {
    const {history} = this.props.route;
    history.push(`/home${history.location.search || ''}`);
  }

  openZipModal() {
    this.props.dispatch(modalActions.openModal({modal: 'ZipCodeModal'}));
  }

  openNoFeesModal() {
    this.props.dispatch(
      modalActions.openModal({
        modal: 'SignUpSuccessModal',
        modalViewed: {
          pageUrl: window.location.pathname,
          loggedIn: true,
          ctaText: SignUpSuccessModalCta,
        },
      }),
    );
  }

  renderStaticModule(type, Module, index) {
    return (
      <div
        key={`static-module-${index}`}
        className={`personalized-home-page__module personalized-home-page__static-module personalized-home-page__module-${type}`}
      >
        <div className="personalized-home-page__module-container">
          <Module />
        </div>
      </div>
    );
  }

  // Disable membership modal https://goodeggs.atlassian.net/browse/ECOMM-36
  // handleCloseBanner() {
  //   this.setState({isMembershipModalOpen: false});
  //   localStorage.setItem(membershipStorageKey, 'true');
  // }

  render() {
    const isLoggedIn = false;

    if (this.props.homepageError) {
      // Buy us some time to send rollbars for client-side errors in reducers etc.
      // Then send us to the market
      setTimeout(() => {
        window.location = `/produce`;
      }, 250);
    }

    const {featuredContentMerchandisingFeatures, otherMerchandisingFeatures} =
      this.props.merchandisingFeatures.reduce(
        (acc, merchandisingFeature) => {
          if (merchandisingFeature.type === 'featured-content') {
            acc.featuredContentMerchandisingFeatures.push(merchandisingFeature);
          } else {
            acc.otherMerchandisingFeatures.push(merchandisingFeature);
          }

          return acc;
        },
        {featuredContentMerchandisingFeatures: [], otherMerchandisingFeatures: []},
      );

    return (
      <div className="personalized-home-page__wrapper">
        <MarketLayout>
          <Helmet>
            <title>
              Organic Grocery Delivery - Meal solutions and grocery items from the Deli, Bakery,
              Pantry, and more.
            </title>
            <meta
              name="google-signin-client_id"
              content={this.props.settings.googleAuthenticateClientId}
            />

            <meta
              name="description"
              property="og:description"
              content="Absurdly fresh groceries and meal-kits delivered same day."
            />
          </Helmet>

          <AnnouncementBanner feature="home" />
          <ReferralBanner />

          <div className="personalized-home-page">
            {this.props.merchandisingFeatures.length > 0 && (
              <div className="personalized-home-page__modules">
                <div className="personalized-home-page__header personalized-home-page__module">
                  <div className="personalized-home-page__module-container">
                    {isLoggedIn && (
                      <div className="personalized-home-page__change-interests">
                        <Link to={`/home/step/interests${this.props.route.location.search || ''}`}>
                          <i className="icon icon-gear" />
                          <span>Change Interests</span>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                {featuredContentMerchandisingFeatures.length > 0 && (
                  <FeaturedContentCarousel>
                    {featuredContentMerchandisingFeatures.map((feature) => (
                      <HomePageModule
                        key={`${feature.type}-${feature.slug}`}
                        module={feature}
                        user={this.props.user}
                        features={this.props.features}
                      />
                    ))}
                  </FeaturedContentCarousel>
                )}
                {otherMerchandisingFeatures.map(
                  function (feature) {
                    return (
                      <HomePageModule
                        key={`${feature.type}-${feature.slug}`}
                        module={feature}
                        user={this.props.user}
                        features={this.props.features}
                      />
                    );
                  }.bind(this),
                )}
              </div>
            )}

            {this.props.merchandisingFeatures.length === 0 ||
            this.props.loadingMerchandisingFeatures ? (
              <div className="personalized-home-page__loading">
                <LoadingOverlay />
              </div>
            ) : null}

            {this.props.showFoodInterests || this.props.route.showFoodInterests ? (
              <InterestsModal onSubmit={this.goHome.bind(this)} />
            ) : null}
            {
              // Disable membership modal https://goodeggs.atlassian.net/browse/ECOMM-36
            }
            {/* {this.state.isMembershipModalOpen && (*/}
            {/*  <MembershipModal onCloseModal={() => this.handleCloseBanner()} />*/}
            {/* )}*/}
          </div>
        </MarketLayout>
      </div>
    );
  }
}

HomeScreen.propTypes = {
  dispatch: PropTypes.func,
  features: PropTypes.array,
  homepageError: PropTypes.object,
  loadingMerchandisingFeatures: PropTypes.bool,
  merchandisingFeatures: PropTypes.array,
  route: PropTypes.object,
  selectedFoodInterests: PropTypes.array,
  showAllFeatures: PropTypes.bool,
  showFoodInterests: PropTypes.bool,
  user: PropTypes.object,
  zipCode: PropTypes.string,
};

const ConnectedHomeScreen = connect((state, props) => ({...state, ...props}))(HomeScreen);

export default ConnectedHomeScreen;
